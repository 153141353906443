<template>
    <n-list value="expenseCategories" text="開支分類" :fields="fields" :items="expenseCategories" />
</template>

<script>
import { mapState } from 'vuex'

export default {
    data() {
        return {
            fields: [
                {
                    key: 'name',
                    label: '名稱',
                    sortable: true,
                },
            ]
        }
    },

    created() {
        this.$store.dispatch('bindCollectionByMerchant', { col: 'expenseCategories', merchantId: localStorage.merchantId })
    },

    computed: {
        ...mapState({
            expenseCategories: state => state.expenseCategories,
        })
    }
}
</script>